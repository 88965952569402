<template>
  <div class="bank-info">
    <BaseHeaderBar
      title="Informasi Bank"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <form class="form-content" @submit.prevent="onSubmit">
      <div>
        <div class="input-block select-block">
          <input
            type="text"
            readonly
            :value="bankName"
            placeholder="Nama Bank"
            @click="showPicker = true"
          />
          <img src="@/assets/img/arrow-down.png" class="arrow-down" />
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              value-key="bank_name"
              :default-index="defaultIndex"
              cancel-button-text="Batal"
              confirm-button-text="Yakin"
              @cancel="showPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
        </div>
        <div class="input-block">
          <input
            v-model.trim="account"
            type="tel"
            minlength="10"
            maxlength="20"
            placeholder="Rekening Bank"
          />
        </div>
        <div class="input-block">
          <input
            v-model.trim="reAccount"
            type="tel"
            minlength="10"
            maxlength="20"
            placeholder="Konfirmasi rekening bank"
          />
        </div>
      </div>
      <button type="submit" class="submit-btn">Submit</button>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup, Picker, Toast } from 'vant'
import { validateBankAccount } from '@/utils/validator'
import request from '@/utils/request'

Vue.use(Popup)
Vue.use(Picker)

export default {
  name: 'BankInfo',
  data () {
    return {
      bankName: '',
      account: '',
      reAccount: '',
      columns: [],
      showPicker: false,
      defaultIndex: 0
    }
  },
  created () {
    this.getBankList()
  },
  methods: {
    getBankList () {
      request.post('badrnkInfoPage')
        .then(res => {
          this.bankName = res.badrnkName
          this.account = this.reAccount = res.badrnkAccount
          const bankList = res.badrnk_list || []
          this.defaultIndex = Math.floor(bankList.length / 2)
          this.columns = res.badrnk_list
          this.abbrBankName = res.gadrteID
        })
    },
    validate () {
      if (!this.bankName) {
        Toast('Silakan pilih nama bank')
        return false
      }
      if (!this.account) {
        Toast('Silakan masukkan nomor rekening bank')
        return false
      }
      if (!this.reAccount) {
        Toast('Silakan konfirmasi nomor rekening bank')
        return false
      }
      if (!validateBankAccount(this.account)) {
        Toast('Format nomor rekening bank salah')
        return false
      }
      if (this.account !== this.reAccount) {
        Toast('Kedua nomor rekening yang dimasukkan tidak sama')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        badrnkName: this.bankName,
        gadrteID: this.abbrBankName,
        badrnkAccount: this.account
      }
      request.post('badrnkInfosubmit', postData)
        .then(() => {
          Toast('Pembaruan data bank berhasil')
          this.$router.back()
        }).finally(() => {
          this.flag = false
        })
    },
    onConfirm (value) {
      this.bankName = value.bank_name
      this.abbrBankName = value.gate_id
      this.showPicker = false
    }
  }
}
</script>

<style scoped lang="less">
.bank-info {
  display: flex;
  flex-direction: column;
}

.form-content {
  padding-left: 30px;
  padding-right: 30px;
  margin: 20px;
}

.input-block {
  border: 2px solid #fff;
  &:nth-child(n + 2) {
    margin-top: 16px;
  }

  input {
    display: block;
    width: 100%;
    line-height: 94px;
    padding-left: 30px;
    border: none;
    font-size: 30px;
    color: #fff;
    height: 94px;
    background: none;
    &::placeholder {
      font-size: 26px;
      color: #fff;
    }
  }
}

.select-block {
  position: relative;
  margin-top: 130px;

  .arrow-down {
    position: absolute;
    top: 50%;
    right: 27px;
    width: 34px;
    height: 21px;
    transform: translateY(-50%);
  }
}

.submit-btn {
  .submit-btn;
  margin-top: 220px;
  margin-bottom: 48px;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}
</style>
